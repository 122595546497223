/* Reset CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body.hall-page-body {  
  align-items: baseline;
  margin-top: 2%;
  background-image: none;
} 

/* Styles for HallPage */
.hall-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  top: 0;
  position: relative;
}

.hall-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 0;
}

.sort-options {
  display: flex;
  align-items: center;
}

.sort-options span {
  margin-right: 10px;
  font-size: 1em;
  color: #65558f;
}

.sort-options select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.therapist-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
  margin-top: 10px; /* Optional: add margin-top to the cards container to create space between header and cards */
}

@media (max-width: 1024px) {
  .hall-page {
    padding: 10px;
  }

  .hall-page-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .sort-options {
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
  }

  .sort-options span {
    margin-right: 5px;
    font-size: 0.9em;
  }

  .sort-options select {
    padding: 4px;
    font-size: 0.9em;
  }
}

@media (max-width: 768px) {
  .therapist-cards-container {
    flex-direction: column;
    align-items: center;
  }

  .therapist-card {
    width: 100%;
    max-width: 400px;
  }
}

@media (max-width: 480px) {
  .hall-page {
    padding: 5px;
  }

  .sort-options span {
    font-size: 0.8em;
  }

  .sort-options select {
    padding: 3px;
    font-size: 0.8em;
  }
}
