/* registrationForm.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.registration-form {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  margin: 40px auto;
  width: 90%;
}

.registration-form h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #65558F;
  font-size: 2.5em;
}

.registration-form form {
  display: flex;
  flex-direction: column;
}

.registration-form input,
.registration-form textarea,
.registration-form select,
.registration-form button {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.registration-form textarea {
  resize: none;
  height: 100px;
}

.registration-form input[type="file"] {
  padding: 0;
}

.registration-form button {
  background-color: #65558F;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.registration-form button:hover {
  background-color: #37084d;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
}

.checkbox-group,
.radio-group {
  display: flex;
  flex-direction: column;
}

.checkbox-group label,
.radio-group label {
  margin-bottom: 10px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-input {
  margin-right: 10px;
  margin-top: 15px;
}

.checkbox-label {
  white-space: nowrap;
}

.registration-step {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.button-group {
  display: flex;
  justify-content: space-between;
}


/* radioButtons.css */

.specialh2{
    margin-bottom: 8px;
}

.radio-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .radio-container input[type="radio"] {
    margin-right: 10px;
  }
  
  .radio-label {
    white-space: nowrap;
    margin-bottom: 12px;
  }
  
@media (max-width: 768px) {
  .registration-form {
    width: 95%;
  }

  .registration-form h1 {
    font-size: 2em;
  }
}

@media (max-width: 480px) {
  .registration-form h1 {
    font-size: 1.5em;
  }

  .registration-form input,
  .registration-form textarea,
  .registration-form select,
  .registration-form button {
    font-size: 0.9em;
  }
}
