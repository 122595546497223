/* Basic modal styles */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  background-color: white;
  border: 1px solid #a0a096;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.calendar-container {
  margin-top: 20px;
  position: relative;
}

.react-calendar {
  width: 100%;
  background-color: white;
  border: 1px solid #a0a096;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px;
  text-align: center;
  background: none;
  border: none;
  outline: none;
}

.react-calendar__tile--active {
  background: #6fa1ff;
  color: white;
  border-radius: 4px;
}

.hours-view {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hour-slot {
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-back-btn,
.modal-schedule-btn {
  background: none;
  border: none;
  cursor: pointer;
  border: none;
  background-color: #65558f;
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  width: 40%;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  margin-top: 20px;

}

button {
 
  /* cursor: pointer;
  border: none;
  background-color: #65558f;
  color: #fff;
 
  border-radius: 50px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  width: 40%;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
 
  width: auto; */
}

.modal-schedule-btn {
  padding: 10px 20px;
  background-color: #6fa1ff;
  color: white;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-schedule-btn:hover {
  background-color: #5a91e8;
}

.toggle-view-btn {
  margin-top: 20px;
  background: none;
  border: none;
  cursor: pointer;
}

.toggle-view-btn svg {
  fill: rgba(0, 0, 0, 1);
  transition: transform 0.2s;
}

.toggle-view-btn:hover svg {
  transform: scale(1.1);
}








/*********************************************************************/
/* width: 100%;
padding: 10px;
border: 1px solid #5738a6;
border-radius: 5px;
color: #5738a6; */

.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 18px;
    background-color: #fef7ff;
    max-width: 905px;
    margin: 0 auto;
    border: 8px solid rgba(202, 196, 208, 1);
    padding: 20px;
  }
  
  .profile-header {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .profile-photo-top {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
  
  .profile-content {
    width: 100%;
    background-color: #fff;
    border-radius: 18px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .profile-info {
    text-align: center;
    width: 100%;
  }
  
  .profile-name {
    font-size: 24px;
    color: #333;
  }
  
  .profile-expertise {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
  }
  
  .expertise-tag {
    display: inline-block;
    background-color: #65558f;
    color: #fff;
    border-radius: 12px;
    padding: 5px 10px;
    margin: 2px;
    font-size: 14px;
  }
  
  .profile-description {
    margin-top: 10px;
    font-size: 16px;
    color: #777;
  }
  
  .profile-button {
    background-color: #65558f;
    color: #fff;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1em;
    width: 40%;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
  }
  
  .profile-button:hover {
    background-color: #37084d;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  }
  
  .profile-video {
    width: 50%;
    border-radius: 10px;
    margin-top: 20px;
  }
  
  .profile-details {
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }
  
  .detail-item {
    font-size: 14px;
    color: #555;
  }
  
  .detail-item > strong {
    color: #333;
  }
  
  .show-more-button,
  .show-less-button {
    background-color: #65558f;
    color: #fff;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 0.8em;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
    display: block;
    width: 50%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .show-more-button:hover,
  .show-less-button:hover {
    background-color: #37084d;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  }
  
  .see-all-therapists-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #6fa1ff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .see-all-therapists-btn:hover {
    background-color: #5a91e8;
  }  


  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 500px;
    background-color: white;
    border: 1px solid #a0a096;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
  }
  
  .calendar-container {
    margin-top: 20px;
    position: relative;
  }
  
  .react-calendar {
    width: 100%;
    background-color: white;
    border: 1px solid #a0a096;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px;
    text-align: center;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .react-calendar__tile--active {
    background: #6fa1ff;
    color: white;
    border-radius: 4px;
  }
  
  .hours-view {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .hour-slot {
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
  }
  
  .hour-slot.selected {
    background-color: #6fa1ff;
    color: white;
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .modal-back-btn,
  .modal-schedule-btn {
    background-color: #65558f;
    color: #fff;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1em;
    width: 40%;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
  
  }
  
  .modal-schedule-btn {
    padding: 10px 20px;
    background-color: #6fa1ff;
    color: white;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .modal-schedule-btn:hover {
    background-color: #5a91e8;
  }
  
  .toggle-view-btn {
    margin-top: 20px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .toggle-view-btn svg {
    fill: rgba(0, 0, 0, 1);
    transition: transform 0.2s;
  }
  
  .toggle-view-btn:hover svg {
    transform: scale(1.1);
  }
  