* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-family: Arial, sans-serif;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(245, 245, 245, 0.8);
  background-image: url('../img/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  max-width: 300px;
  cursor: pointer;
}

.lph {
  background-color: #0202024b;
  width: 80vw;
  min-height: 80vh;
  padding: 2%;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.lphh {
  background-color: #02020227;
  width: 80vw;
  max-height: 80vh;
  padding: 2%;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.308);
}

.lph-title {
  margin-bottom: 2%;
  text-align: center;
  width: 80%;
}

.lph-title h1 {
  font-size: 3vw;
}

.tent {
  text-align: center;
  margin-bottom: 20px;
}

.tent h1 {
  font-size: 5vw;
  margin: 0 20px;
}

.tent h2 {
  font-size: 3vw;
  margin-top: 2%;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.login-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.login button {
  padding: 20px 30px;
  margin-top: 20%;
  font-size: 2vw;
  color: #fff;
  background-color: #65558F;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.login button:hover {
  background-color: #37084d;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
}

.login-links {
  margin-top: 5%;
  text-align: center;
}

.login-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
  display: block;
  margin-top: 1%;
}

.login-link:hover {
  text-decoration: underline;
}

.link-su {
  margin-top: 10%;
}

.user-type-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
}

.user-type-buttons {
  display: flex;
  gap: 20px;
}

.user-type-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #65558F;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-type-button:hover {
  background-color: #37084d;
}

.user-type-selection h1 {
  margin-bottom: 20px;
  font-size: 3rem;
  color: white;
}

.user-type-selection .buttons {
  display: flex;
  gap: 30px;
  margin-top: 5%;
}

.user-type-selection button {
  padding: 10px 20px;
  font-size: 1.5rem;
  color: #fff;
  background-color: #65558F;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-type-selection button:hover {
  background-color: #37084d;
}

/* Responsividade */
@media (max-width: 768px) {
  .tent h1 {
    font-size: 6vw;
  }

  .tent h2 {
    font-size: 4vw;
  }

  .login button {
    font-size: 3vw;
  }

  .login-input {
    font-size: 0.9rem;
  }

  .link-su {
    margin-top: 5%;
  }

  .user-type-selection h1 {
    font-size: 2.5rem;
  }

  .user-type-selection .buttons {
    flex-direction: column;
    gap: 20px;
  }

  .user-type-selection button {
    font-size: 1.2rem;
    padding: 10px 15px;
  }

  .lph-title h1 {
    font-size: 8vw;
  }

  .tent h1 {
    font-size: 7vw;
  }

  .tent h2 {
    font-size: 5vw;
  }

  .login button {
    margin-bottom: 8%;
  }
}

@media (max-width: 480px) {
  .tent h1 {
    font-size: 8vw;
  }

  .login-input {
    font-size: 0.9rem;
  }

  .tent h2 {
    font-size: 6vw;
  }

  .login button {
    font-size: 4vw;
  }

  .user-type-selection h1 {
    font-size: 2rem;
  }

  .user-type-selection .buttons {
    flex-direction: column;
    gap: 15px;
  }

  .user-type-selection button {
    font-size: 1rem;
    padding: 8px 12px;
  }

  .lph-title h1 {
    font-size: 8vw;
  }

  .tent h1 {
    font-size: 9vw;
  }

  .tent h2 {
    font-size: 7vw;
  }
}



/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-family: Arial, sans-serif;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(245, 245, 245, 0.8);
  background-image: url('../img/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}
.logo-container {
  text-align: center;
  margin-bottom: 20px;

}

.logo {
  max-width: 200px;
  cursor: pointer;
}
.lph {
  background-color: #0202024b;
  width: 80vw;
  min-height: 80vh;
  padding: 2%;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.lphh {
  background-color: #02020227;
  width: 80vw;
  max-height: 80vh;
  padding: 2%;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.308);
}


.lph-title{
  margin-bottom: 2%;
  text-align: center;
  width: 80%;
}

.lph-title h1{
  font-size: 5vw;
}

.tent {
  text-align: center;
  margin-bottom: 20px;
}

.tent h1 {
  font-size: 5vw;
  margin: 0 20px;
}

.tent h2 {
  font-size: 3vw;
  margin-top: 2%;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.login-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.login button {
  padding: 20px 30px;
  margin-top: 20%;
  font-size: 2.5vw;
  color: #fff;
  background-color: #65558F;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.login button:hover {
  background-color: #37084d;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
}

.login-links {
  margin-top: 5%;
  text-align: center;
}

.login-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
  display: block;
  margin-top: 1%;
}

.login-link:hover {
  text-decoration: underline;
}

.link-su{
  margin-top: 10%;
}

.user-type-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
}

.user-type-buttons {
  display: flex;
  gap: 20px;
}

.user-type-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #65558F;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-type-button:hover {
  background-color: #37084d;
}

.user-type-selection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
}

.user-type-selection h1 {
  margin-bottom: 20px;
  font-size: 3rem;
  color: #333;
  color: white;

}

.user-type-selection .buttons {
  display: flex;
  gap: 30px;
  margin-top: 5%;
}

.user-type-selection button {
  padding: 10px 20px;
  font-size: 1.5rem;
  color: #fff;
  background-color: #65558F;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-type-selection button:hover {
  background-color: #37084d;
}


@media (max-width: 768px) {
  .tent h1 {
    font-size: 6vw;
  }

  .tent h2 {
    font-size: 4vw;
  }

  .login button {
    font-size: 3vw;
  }

  .login-input {
    font-size: 0.9rem;
  }

  .link-su {
    margin-top: 5%;
  }
}

@media (max-width: 480px) {
  .tent h1 {
    font-size: 8vw;
  }

  .login-input {
    font-size: 0.9rem;
  }
  .tent h2 {
    font-size: 6vw;
  }

  .login button {
    font-size: 4vw;
  }

  .login-input {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .user-type-selection h1 {
    font-size: 2.5rem;
  }

  .user-type-selection .buttons {
    flex-direction: column;
    gap: 20px;
  }

  .user-type-selection button {
    font-size: 1.2rem;
    padding: 10px 15px;
  }
}

@media (max-width: 480px) {
  .user-type-selection h1 {
    font-size: 2rem;
  }

  .user-type-selection .buttons {
    flex-direction: column;
    gap: 15px;
  }

  .user-type-selection button {
    font-size: 1rem;
    padding: 8px 12px;
  }
}

@media (max-width: 768px) {
  .lph-title h1 {
    font-size: 8vw;
  }

  .tent h1 {
    font-size: 7vw;
  }

  .tent h2 {
    font-size: 5vw;
  }
  .login button {
    margin-bottom: 8%;
  }
}

@media (max-width: 480px) {
  .lph-title h1 {
    font-size: 8vw;
  }

  .tent h1 {
    font-size: 9vw;
  }

  .tent h2 {
    font-size: 7vw;
  }
} */