/* Reset CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Search Menu Styles */
.search-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 5%;
}

.search-bar {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-right: 20px;
}

.search-icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.search-icon svg {
  width: 16px;
  height: 16px;
  color: #5738a6;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: 1px solid #5738a6;
  border-radius: 5px;
  color: #5738a6;
}

/* Placeholder color */
.search-bar input::placeholder {
  color: #5738a6;
  opacity: 1;
}

.search-bar input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #5738a6;
}

.search-bar input::-ms-input-placeholder { /* Microsoft Edge */
  color: #5738a6;
}

.search-bar input:focus {
  border-color: #65558f;
  outline: none; /* Optional: Remove the default focus outline */
}

/* Filter Options Styles */
.filter-options {
  margin-left: 10px;
}

.dropdown-toggle {
  border: 1px solid #5738a6;
  color: #5738a6;
}

.dropdown-toggle:hover,
.dropdown-toggle:focus,
.dropdown-toggle:active {
  background-color: #5738a6;
  color: #fff;
}

.dropdown-menu {
  min-width: 200px;
  border: 1px solid #5738a6;
}

.dropdown-menu .dropdown-header {
  font-size: 1em;
  color: #5738a6;
  margin-bottom: 5px;
}

.dropdown-item {
  padding: 10px 20px;
  color: #5738a6;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #5738a6;
  color: #fff;
}

.dropdown-divider {
  border-top: 1px solid #5738a6;
  margin: 5px 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .search-menu {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-bar {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }

  .filter-options {
    width: 80%;
    margin-left: 0;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto

  }

  .dropdown-toggle {
    width: 100%;
  }
}
