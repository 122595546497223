.management-modal {
  position: fixed;
  top: 50px;
  right: 50px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #5738a6;
  padding-bottom: 10px;
}

.modal-header h2 {
  margin: 0;
}

.close-button {
  background: #5738a6;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.column {
  flex: 1;
  margin: 0 10px;
}

.column h3 {
  text-align: center;
  background-color: #ffffff;
  color: #5738a6;
  border: 1px solid #5738a6;
  padding: 10px;
  border-radius: 5px;
}

.card {
  background-color: #c4badd;
  border: 1px solid #5738a6;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.card.expanded {
  max-height: none; /* Remove height restriction */
}

.card p {
  margin: 5px 0;
}

.card button {
  margin-right: 10px;
  background-color: #5738a6;
  color: rgb(0, 0, 0);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.card button:hover {
  background-color: #452f7a;
}

.expanded-content {
  margin-top: 10px;
}
