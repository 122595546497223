/* TherapistCard.css */

.therapist-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 250px  !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.2s ease-in-out;
  margin: 20px; /* Added margin for spacing between cards */
}

.therapist-card:hover {
  transform: translateY(-5px);
}

.therapist-photo {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-bottom: 1px solid #e0e0e0;
}

.therapist-info {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.therapist-info h3 {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 8px;
}

.custom-badge {
  background-color: #653ad0b8;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rating {
  padding: 10px;
}

.language {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.expertise-tag {
  background-color: #65558f;
  color: #fff;
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 0.8em;
  white-space: nowrap;
}

.description {
  font-size: 0.9em;
  color: #777;
  margin-bottom: 8px;
}

.price,
.rating,
.sessions {
  font-size: 1em;
  color: #333;
  font-weight: bold;
  margin-bottom: 4px;
}

.language svg.language-icon {
  margin-right: 8px;
}

.custom-schedule-btn {
  color: #5738a6;
  margin-bottom: 10%;
  padding: 3%;
  border: 1px solid #5738a6; /* Especifica a cor da borda para todos os lados */
  border-radius: 6px;
  background-color: #fff;
  width: 60%;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.custom-schedule-btn:hover {
  background-color: #5738a6;
  color: white;
  border-color: #5738a6; /* Garante que a cor da borda no hover seja consistente */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .therapist-card {
    max-width: 250px;
    margin: 10px 0; 
  }
  
  .therapist-cards-container {
    flex-direction: column;
    align-items: center;
  }
}

